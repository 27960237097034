import React, { useState, useEffect, useRef } from 'react';
import { useWidgetContext } from './Widget/Provider';

const Focuser = ({id = 'default', focus = true}) => {

    if (!focus) {
        return null;
    }

    return <Inner id={id} />
}

const Inner = ({id}) => {
    const focusRef = useRef(null);
    const [done, setDone] = useState(null);
    const {scrollOffset, widgetRef} = useWidgetContext();

    useEffect(
        () => getFocus(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    const getFocus = () => {
        if (done === id) {
            return;
        }

        setTimeout(() => {
            const ref = widgetRef?.current ?? focusRef?.current ?? null;
            if (!ref) {
                getFocus();
                return;
            }

            const y = ref.getBoundingClientRect().top + window.scrollY + scrollOffset;
            window.scrollTo({top: y, behavior: 'smooth'});

            setDone(id);
        }, 100);
    }

    return (
        <span ref={focusRef}></span>
    )
}

export default Focuser;