import {useEffect} from 'react';

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const match = (key, obj, fallback = null) => {
    return obj[key] ?? obj['default'] ?? fallback
}

const sortFrequencies = (a, b) => {
    const getFrequencyValue = (frequency) => {
        if (frequency === 'Once' || frequency === 'Other') {
            return 0;
        }

        const count = parseInt(frequency.replace(/\D/g, ''));
        const type = frequency.replace(count ?? '', '').replace('Every', '').trim();
        const weights = {
            Weeks: 7,
            Months: 30,
            Years: 365,
        };
        return (count ?? 1) * (weights[type] ?? 1);
    };

    return getFrequencyValue(a) - getFrequencyValue(b);
}

const numPad = (str) => {
    return String(str).length === 1 ? '0' + str : str;
}

const numFormat = (num, hideDecimals) => {
    if (num === null || num === undefined) {
        return 0;
    }
    const str = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (hideDecimals) {
        return str.split('.')[0];
    } else if (hideDecimals === false) {
        const parts = str.split('.');
        if (parts[1] === undefined) {
            parts[1] = '00';
        } else if (parts[1] && parts[1].length < 2) {
            parts[1] += '0'.repeat(2 - parts[1].length);
        }
        return parts.join('.');
    }
    return str;
}

const useVisibilityChange = (handleVisibilityChange, onlyVisible = true) => {
    const onVisibilityChange = () => {
        let eventName;
        if (typeof document.hidden !== 'undefined') {
            eventName = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
            eventName = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
            eventName = 'webkitvisibilitychange'
        }

        const theFunction = () => {
            if (onlyVisible && isDocumentHidden()) {
                return
            }
            handleVisibilityChange(isDocumentHidden())
        };

        document.addEventListener(eventName, theFunction, false);

        return () => {
            document.removeEventListener(eventName, theFunction, false)
        };
    }

    useEffect(() => {
        return onVisibilityChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

const isDocumentHidden = () => {
    if (typeof document.hidden !== 'undefined') {
        return document['hidden'];
    } else if (typeof document.msHidden !== 'undefined') {
        return document['msHidden'];
    } else if (typeof document.webkitHidden !== 'undefined') {
        return document['webkitHidden'];
    }
}

const isApplePayDevice = () => {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) && !/Chrome/i.test(navigator.userAgent)
}

export {
    classNames,
    match,
    sortFrequencies,
    numPad,
    numFormat,
    useVisibilityChange,
    isDocumentHidden,
    isApplePayDevice,
}