import React from 'react';
import Text from './Text';

const Message = ({ message, title, type, dismiss }) => {
    const classes = {
        info: 'alert-info',
        error: 'alert-danger',
        success: 'alert-success',
        warning: 'alert-warning',
    };

    if (!message || !type) {
        return null;
    }

    return <div className={`alert ${classes[type] ?? 'alert-info'} d-flex flex-row justify-content-between align-items-center`} role="alert">
        <div>
            {title && <h5 className="alert-heading">{title}</h5>}
            <p className="mb-0"><Text text={message} /></p>
        </div>
        {dismiss && <button type="button"
            className="btn-close"
            aria-label="Close"
            onClick={dismiss}
        />}
    </div>
}

export default Message;