import React from 'react';
import Widget from '../Components/Widget';
import PortalLoader from './PortalLoader';

const Standalone = ({options, attributes}) => {
    return <Widget {...{options, attributes}} focusOnStart={'portal'}>
        <PortalLoader />
    </Widget>
}

export default Standalone;