import React, { Suspense, lazy } from 'react';
import { PageLoading } from '../Components/Loading';

const Combined = lazy(() => import(/* webpackPreload: true, webpackChunkName: "modules" */'./Combined'));

const CombinedLoader = (props) => {
    return <Suspense fallback={<PageLoading />}>
        <Combined {...props} />
    </Suspense>
}

export default CombinedLoader;