import React, { Suspense, lazy } from 'react';
import { PageLoading } from '../Components/Loading';

const Documents = lazy(() => import(/* webpackPreload: true, webpackChunkName: "modules" */'./Documents'));

const DocumentsLoader = (props) => {
    return <Suspense fallback={<PageLoading />}>
        <Documents {...props} />
    </Suspense>
}

export default DocumentsLoader;