
const Session = class {
    // #getsoapy-customer-01HY6FYB3T970RJKVDFWP56696-ZYOUiqEDa2GBcBnwtwxFT274mBfAK2rr9ck9bQR1lDEMJK8iTL
    // #getsoapy-01J13JTGY5HJ5SVP9KXE2PV2BT-dJGlfL2JsGoIljAjrEELdNkRJj16RiUKN87B1Uyp1GqHx2JlYi

    models = [
        {
            model: 'quote',
            regex: /#?getsoapy-(?<quoteId>[a-zA-Z0-9]{26})-(?<quoteSecret>[a-zA-Z0-9]{50})/,
            keys: ['quoteId', 'quoteSecret'],
            makeHash: (data) => `#getsoapy-${data?.quoteId ?? ''}-${data?.quoteSecret ?? ''}`,
        },
        {
            model: 'customer',
            regex: /#?getsoapy-customer-(?<customerId>[a-zA-Z0-9]{26})-(?<customerSecret>[a-zA-Z0-9]{50})/,
            keys: ['customerId', 'customerSecret'],
            makeHash: (data) => `#getsoapy-customer-${data?.customerId ?? ''}-${data?.customerSecret ?? ''}`,
        }
    ]

    constructor() {
        this.parseHash();
    }

    findAvailableSession() {
        return this.models.reduce((prev, config) => {
            if (config.keys.every(key => window.localStorage.getItem(key))) {
                return config.model;
            } else {
                return prev;
            }
        }, false);
    }

    getSessionData() {
        const model = this.findAvailableSession();
        if (!model) {
            return null;
        }

        return this.models.find(config => config.model === model).keys.reduce((prev, key) => {
            prev[key] = window.localStorage.getItem(key);
            return prev;
        }, {entity: model});
    }

    modelConfig(model) {
        return this.models.find(config => config.model === model);
    }

    parseHash() {
        this.models.map(config => {
            const matches = window.location.hash.match(config.regex);
            if (!matches) {
                return
            }

            this.persist(config.model, matches.groups);
        });
    }

    persist(model, data) {
        this.models.map(config => {
            if (config.model === model) {
                config.keys.map(key => {
                    window.localStorage.setItem(key, data[key] ?? null);
                });
                this.setHash(model, data);
            } else {
                config.keys.map(key => {
                    window.localStorage.removeItem(key);
                });
            }
        });
    }

    clearSession() {
        this.models.map(config => {
            config.keys.map(key => {
                window.localStorage.removeItem(key);
            });
        });
        window.location.hash = '';
    }

    setHash(model, data) {
        const config = this.modelConfig(model);
        window.location.hash = config.makeHash(data);
    }
}

export default new Session();