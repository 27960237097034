
import Callback from "./Callback";

const displacedModalsCallback = new Callback();
const displacedModals = {};

export default new class UI {
    breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
    }

    meta;

    xs() {
        return window.innerWidth < this.breakpoints.sm;
    }

    sm() {
        return window.innerWidth >= this.breakpoints.sm;
    }

    md() {
        return window.innerWidth >= this.breakpoints.md;
    }

    lg() {
        return window.innerWidth >= this.breakpoints.lg;
    }

    xl() {
        return window.innerWidth >= this.breakpoints.xl;
    }

    enforceScale() {
        this.meta = document.createElement('meta');
        this.meta.id = 'getSoapy-meta';
        this.meta.name = 'viewport';
        this.meta.content = 'width=device-width, initial-scale=1';

        document.getElementsByTagName('head')[0].appendChild(this.meta);

        document.getElementsByTagName('body')[0].className += ' gs-disable-body-scrolling';
    }

    unenforceScale() {
        console.log('unenforceScale')
        document.getElementsByTagName('head')[0].removeChild(this.meta);

        document.getElementsByTagName('body')[0].className = document.getElementsByTagName('body')[0].className.replace(' gs-disable-body-scrolling', '');
    }

    preloadFonts() {
        const fonts = [
            'fa-duotone-900.woff2'
        ];

        fonts.map(font => {
            const link = document.createElement('link');
            link.href = 'https://getsoapy.com/fonts/' + font;
            link.id = 'getSoapy-Font-' + font;
            link.rel = 'preload';
            link.type = 'font/woff2';
            link.as = 'font';
            link.crossorigin = 'crossorigin';
            document.getElementsByTagName('head')[0].appendChild(link);

            // Return from an popover-arrow function to stop the nagging
            return null;
        })
    }
    /*
        preloadImages() {
            const urls = [
                'https://getsoapy.com/embed/images/casement.jpg',
                'https://getsoapy.com/embed/images/detached-2.png',
                'https://getsoapy.com/embed/images/detached.png',
                'https://getsoapy.com/embed/images/door.jpg',
                'https://getsoapy.com/embed/images/flat.png',
                'https://getsoapy.com/embed/images/sash_case.jpg',
                'https://getsoapy.com/embed/images/semidetached.png',
                'https://getsoapy.com/embed/images/skylight.jpg',
                'https://getsoapy.com/embed/images/terraced.png',
                'https://getsoapy.com/embed/images/tilt_turn.jpg',
            ]

            urls.map(url => {
                const img = new Image();
                img.src = url;

                // Return from an popover-arrow function to stop the nagging
                return null;
            });
        }
     */
    onVisibilityChange(handleVisibilityChange, onlyVisible) {
        let eventName;
        if (typeof document.hidden !== 'undefined') {
            eventName = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
            eventName = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
            eventName = 'webkitvisibilitychange'
        }

        document.addEventListener(eventName, () => {
            if (onlyVisible && this.isDocumentHidden() === true) {
                return
            }
            handleVisibilityChange()
        }, false);

        return () => document.removeEventListener(eventName, handleVisibilityChange, false);
    }

    isDocumentHidden = () => {
        if (typeof document.hidden !== 'undefined') {
            return document['hidden'];
        } else if (typeof document.msHidden !== 'undefined') {
            return document['msHidden'];
        } else if (typeof document.webkitHidden !== 'undefined') {
            return document['webkitHidden'];
        }
    }

    isDocumentVisible = () => !this.isDocumentHidden()

    isApplePay = () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) && !/Chrome/i.test(navigator.userAgent)

    registerDisplacedModal = (id, modal) => {
        displacedModals[id] = modal;
        displacedModalsCallback.invoke();
    }

    deregisterDisplacedModal = (id) => {
        delete displacedModals[id];
        displacedModalsCallback.invoke();
    }

    getDisplacedModalsCallback = () => {
        return displacedModalsCallback;
    }

    getDisplacedModals = () => {
        return displacedModals;
    }

}();
