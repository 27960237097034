import React from 'react';
import Widget from '../Components/Widget';
import QuoteLoader from './QuoteLoader';
import TheModal from '../Components/Modal/index';

const Modal = ({options, attributes, onClose}) => {
    return <Widget {...{options, attributes}} chrome={false}>
        <TheModal isOpen={true} setIsOpen={() => onClose()}>
            <QuoteLoader />
        </TheModal>
    </Widget>
}

export default Modal;