import React from 'react';

import {ReactComponent as SolidSpinner} from '../../../Icons/Solid/spinner-third.svg';
import {ReactComponent as LightSpinner} from '../../../Icons/Light/spinner-third.svg';
import { classNames } from '../../../Services/helpers';

const Loading = ({className, size = 'm', bg = "light", center}) => {
    const sizes = {
        's': {width: '1rem', height: '1rem'},
        'm': {width: '1.5rem', height: '1.5rem'},
        'l': {width: '2rem', height: '2rem'},
        'xl': {width: '3rem', height: '3rem'},
    }

    const fullClassName = classNames(
        'd-inline-block position-relative',
        center && 'm-auto',
        className,
    );

    const solidColour = bg === 'dark' ? 'fill-brand-light' : 'fill-brand';
    const lightColour = bg === 'dark' ? 'fill-white' : 'fill-brand-light';

    const spinner = <div className={fullClassName} style={sizes[size] ?? sizes['m']}>
        <SolidSpinner className={'animate-spin position-absolute h-100 w-100 '+solidColour} style={{top: '0px', left: '0px'}} />
        <LightSpinner className={'animate-spin-fast position-absolute h-100 w-100 '+lightColour} style={{top: '0px', left: '0px'}} />
    </div>

    return center ? <div className="text-center">{spinner}</div> : spinner;
}

const PageLoading = ({short}) => {
    return <>
        <Loading size="xl" className={short ? 'my-5' : 'my-20'} center />
    </>
}

export default Loading;

export {
    PageLoading,
}