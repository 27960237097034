import React from 'react';
import Widget from '../Components/Widget';
import PaymentLoader from './PaymentLoader';

const Standalone = ({options, attributes}) => {
    return <Widget {...{options, attributes}} focusOnStart={'pay'}>
        <PaymentLoader />
    </Widget>
}

export default Standalone;