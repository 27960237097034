import React, { Suspense, lazy } from 'react';
import Loading from '../Components/Loading';

const Quote = lazy(() => import(/* webpackPreload: true, webpackChunkName: "modules" */'./Quote'));

const QuoteLoader = (props) => {
    return <Suspense fallback={<Loading />}>
        <Quote {...props} />
    </Suspense>
}

export default QuoteLoader;