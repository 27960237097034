import { ulid } from 'ulid';

const LOCAL_DOMAIN = 'http://localhost:8000/api/';
const PRODUCTION_DOMAIN = 'https://getsoapy.com/api/';

export default new class API {

    baseUrl;

    business;

    stripeId = null;

    stripeKey = null;

    testMode = null;

    paymentMethods = {};

    acceptTips = false;

    descriptor = false;

    bankDetails = {};

    services = [];

    documents = [];

    activeCalls = 0;

    onErrorCallbacks = [];

    features = {};

    location = null;

    initialised = new Promise((resolve, reject) => {
        this.initialisedResolver = resolve;
        this.initialisedRejecter = reject;
    });

    constructor() {
        if (window.location.href.indexOf('localhost') !== -1) {
            this.baseUrl = LOCAL_DOMAIN;
        } else {
            this.baseUrl = PRODUCTION_DOMAIN;
        }

        //
    }

    init(businessId, baseUrl = null) {
        if (baseUrl) {
            this.baseUrl = baseUrl;
        }

        return this.get('embed/start?businessId=' + businessId, true).then(result => {
            this.business = result.business;
            this.services = result.services;
            this.paymentMethods = result.methods;
            this.acceptTips = result.acceptTips ?? false;
            this.descriptor = result.descriptor ?? 'getSoapy';
            this.features = result.features;
            this.location = result.location;
            this.documents = result.documents;

            if (result.account === null) {
                return;
            }

            if (result.account.stripe) {
                this.bankDetails = result.account.bank;
                this.stripeId = result.account.stripe.stripeId;
                this.stripeKey = result.account.stripe.stripeKey;
                this.testMode = result.account.stripe.testMode;
            }

            this.initialisedResolver();
        })
    }

    isBusy() {
        return this.activeCalls > 0;
    }

    onError(callback) {
        this.onErrorCallbacks.push(callback);
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Request-Id': ulid(),
            'X-Business-Id': window.getSoapy.getBusinessId(),
            'X-Visitor-Id': this.getCookie('visitor-id'),
        }
    }

    getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return null;
    }

    setCookie(cname, value, days) {
        let expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = cname + '=' + (value || '') + expires + '; path=/';
    }

    get(path, skipHeaders) {
        const config = {};
        if (skipHeaders !== true) {
            config.headers = this.getHeaders();
        }
        return this.call(path, config);
    }

    post(path, data = null) {
        return this.call(path, {
            method: 'post',
            headers: this.getHeaders(),
            body: data ? JSON.stringify(data) : null
        });
    }

    delete(path, data = null) {
        return this.call(path, {
            method: 'delete',
            headers: this.getHeaders(),
            body: data ? JSON.stringify(data) : null
        });
    }

    call(path, config) {
        config.credentials = 'same-origin';
        const url = this.baseUrl + path.replace(/^\//, '');
        const promise = new Promise((resolve, reject) => {
            return fetch(url, config).then(response => {
                this.handleResponse(response, resolve, reject);
                this.activeCalls--;
            }).catch(err => {
                console.error(err);
                reject([
                    {
                        code: 0,
                        title: err?.errors?.[0]?.title ?? err.message ?? 'An error has occurred',
                        detail: err?.errors?.[0]?.details ?? err.message ?? 'An error has occurred',
                    }
                ]);
                this.activeCalls--;
            });
        });

        this.activeCalls++;

        return promise;
    }

    async handleResponse(response, resolve, reject) {
        const data = await response.json();

        if (data.success !== true) {
            this.callErrorCallbacks(data.errors);
            reject(data.errors);
        } else {
            resolve(data.data);
        }
    }

    callErrorCallbacks(errors) {
        this.onErrorCallbacks.forEach(fn => {
            if (!fn && typeof fn !== 'function') {
                return null;
            }

            try {
                fn(errors || 'Sorry, an unhandled error occurred.');
            } catch (e) {
                console.error(e);
            }
        })
    }

    parseErrorResponse(err, fallbackMessage) {
        if (typeof err === 'string') {
            return err;
        }

        if (err === null || err === undefined) {
            return null;
        }

        if (err && err[0] && err[0].detail) {
            return err[0].detail
        }

        if (err.message) {
            return err.message;
        }

        return fallbackMessage ? fallbackMessage : 'An error has occurred'
    }

    getStripeId() {
        return this.stripeId;
    }

    getStripeKey() {
        return this.stripeKey;
    }

    getTestMode() {
        return this.testMode;
    }

    getCurrentDateTime() {
        const pad = (str) => String(str).length === 1 ? '0' + str : str;

        const date = new Date();
        return [
            date.getUTCFullYear(),
            '-',
            pad(date.getUTCMonth() + 1),
            '-',
            pad(date.getUTCDate()),
            ' ',
            pad(date.getUTCHours()),
            ':',
            pad(date.getUTCMinutes()),
            ':',
            pad(date.getUTCSeconds()),
        ].join('')
    }

    trackPageView(data) {
        this.post('embed/pageView', data).then(console.log).catch(console.error);
    }

    hasFeature(feature) {
        const disableFeatures = [
            // 'DOCUMENTS',
            // 'CUSTOMER_PORTAL'
        ];

        if (disableFeatures.indexOf(feature) !== -1 && window.location.href.indexOf('localhost') !== -1) {
            return false;
        }

        return this.features[feature]?.enabled === true;
    }
}()
