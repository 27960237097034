import React from 'react';

export default (props) => {
    if (props.loading === false) {
        return null;
    }

    const size = props.size ?? 'l';
    const classes = {
        s: 'sm',
        m: 'fa-2x', // ??
        l: 'fa-3x'
    }

    const color = props.white ? 'text-white' : 'text-primary';

    const before = props.before ? ' ms-2' : '';
    const after = props.after ? ' me-2' : '';

    const spinner = (
        <i className={'fal fa-spinner-third animate-spin-fast ' + color + ' ' + before + after} style={{ display: 'inline-block' }}></i>
    );

    if (props.inline) {
        return (
            <span className={'text-center ' + classes[size]+' '+props.className}>
                {props.before}
                {spinner}
                {props.after}
            </span>
        );

    } else {
        return (
            <div className={'text-center ' + classes[size]+' '+props.className}>
                {spinner}
            </div>
        );
    }
}
