import React from 'react';
import Widget from '../Components/Widget';
import QuoteLoader from './QuoteLoader';

const Standalone = ({options, attributes}) => {
    return <Widget {...{options, attributes}} focusOnStart={'quote'}>
        <QuoteLoader />
    </Widget>
}

export default Standalone;