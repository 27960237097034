import React from 'react';

const Text = ({children, text, rich}) => {
    if (rich) {
        // Test for any HTML and output it as such
        if (/<\/?[a-z][\s\S]*>/i.test(rich)) {
            return <div dangerouslySetInnerHTML={{ __html: rich }} />
        } else {
            // Or add <p> and <br /> as needed
            return rich.split("\n\n").map((para, i) => {
                return <p key={'para' + i}>{
                    para.split("\n").map((l, x) => {
                        const text = l.replace(/\*\*(.+)\*\*/, '<strong>$1</strong>')
                            .replace(/\*(.+)\*/, '<em>$1</em>');
                        return <span key={'br' + x} dangerouslySetInnerHTML={{ __html: text + '<br />' }} />
                    })
                }</p>
            })
        }
    }

    if (text && typeof text === 'string') {
        return text.split('\n').map((item, key)  => <span key={key}>{item}<br /></span>)
    } else if (text) {
        return text;
    }

    if (typeof children === 'string') {
        return children.split('\n').map((item, key)  => <span key={key}>{item}<br /></span>)
    } else if (children) {
        return children;
    }

    return null;
}

export default Text;
