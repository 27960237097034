
const Callback =  class {

    listeners = [];

    listen(callback) {
        this.listeners.push(callback);

        return () => this.remove(callback);
    }

    remove(callback) {
        this.listeners = this.listeners.filter(c => c !== callback);
    }

    invoke(param1, param2, param3) {
        return new Promise((resolve) => {
            this.listeners.map(async (callback) => {
                if (callback) {
                    await callback(param1, param2, param3)
                }
                resolve();
            })
        })
    }
}

export default Callback;