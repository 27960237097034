import React from 'react';

export default (props) => {
    if (!props.title && !props.children) {
        return null;
    }

    return (
        <div className="row mb-2">
            <div className="col">
                <h2>{props.title || props.children}</h2>
            </div>
        </div>
    );
}
