import React from 'react';

export default ({widgetStyle}) => {
    return (
        <div className={'content ' + widgetStyle}>
            <p>Sorry, there was a problem initialising this widget.</p>
            <p>Please try again later.</p>
        </div>
    )
}
