import React, { Suspense, lazy } from 'react';
import { PageLoading } from '../Components/Loading';

const QuoteManager = lazy(() => import(/* webpackPreload: true, webpackChunkName: "modules" */'./QuoteManager'));

const QuoteLoader = (props) => {
    return <Suspense fallback={<PageLoading />}>
        <QuoteManager {...props} />
    </Suspense>
}

export default QuoteLoader;