import React, { Suspense, lazy } from 'react';
import Loading from '../Components/Loading';

const Documents = lazy(() => import(/* webpackPreload: true, webpackChunkName: "modules" */'./Documents'));

export default (props) => {
    return <Suspense fallback={<Loading />}>
        <Documents {...props} />
    </Suspense>
}
