import React, { useState, useRef, createContext, useContext } from 'react';
import Callback from '../../../Services/Callback';

const ModalContext = createContext();

const useModalContext = () => {
    return useContext(ModalContext);
};

const Provider = ({ children }) => {
    const closer = useRef(new Callback());
    const [isDismissingPrevented, setIsDismissingPrevented] = useState(null);

    const providerValues = {
        closeModal: () => closer.current.invoke(),
        closer: closer.current,
        preventDismissing: (val) => setIsDismissingPrevented(val),
        isDismissingPrevented,
    }

    return (
        <ModalContext.Provider value={providerValues}>
            {children}
        </ModalContext.Provider>
    );
};

export {
    useModalContext,
    Provider,
}
