import React, { useState, useEffect } from 'react';
import { ulid } from 'ulid';
import UI from '../../Services/UI';

let modals = {};
const displacedModalsCallback = UI.getDisplacedModalsCallback();

export default () => {

    const [forceUpdate, setForceUpdate] = useState();

    useEffect(() => {
        displacedModalsCallback.listen(() => {
            modals = UI.getDisplacedModals();
            setForceUpdate(ulid());
        });
    }, [])

    return <>
        <span className="d-none">{forceUpdate}</span>
        {Object.keys(modals).map(id => {
            return <div key={'modal-' + id} style={{ zIndex: getZindex(id) }}>
                {modals[id]}
                <h1>{id}</h1>
            </div>
        })}
    </>
}

const getZindex = (id) => {
    const count = Object.keys(modals).indexOf(id) + 1;
    return 1130 + (count * 10);
}
