import React, {useEffect, Suspense, lazy} from 'react';
import {Provider, useModalContext} from './Provider';
const TheModal = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "modules" */'./TheModal'));

const Modal = (params) => {
    return <Provider>
        <Suspense fallback={null}>
            <TheModal {...params} />
        </Suspense>
    </Provider>
}

const usePreventDismissing = (val) => {
    const modalContext = useModalContext();

    useEffect(() => {
        if (!modalContext) {
            return;
        }

        modalContext.preventDismissing(val);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val]);
}

export default Modal;

export {
    useModalContext as useModal,
    usePreventDismissing,
}
