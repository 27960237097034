import React, { useState, useEffect } from 'react';
import { Provider, useWidgetContext } from './Provider';
import Message from '../Formatting/Message';
import Focuser from '../Focuser';
import { ulid } from 'ulid';

const Widget = ({options, attributes, focusOnStart, children}) => {

    return <Provider {...{options, attributes}}>
        <Inner focusOnStart={focusOnStart}>
            {children}
        </Inner>
    </Provider>
}

const Inner = ({children, focusOnStart}) => {
    const {notification, showError, startListener, widgetStyle, widgetRef} = useWidgetContext();
    const [focus, setFocus] = useState(null);

    useEffect(() => {
        if (!focusOnStart) {
            return;
        }

        startListener(focusOnStart, () => setFocus(ulid()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusOnStart]);


    return <div ref={widgetRef} className={`getsoapy v2 content ${widgetStyle}`}>
        <Focuser focus={focus !== null} id={focus} />
        {notification && <Message {...notification} dismiss={() => showError(null)} />}
        {children}
    </div>
}

export default Widget;