import React from 'react';
import Title from '../Components/Title';

export default ({ rows, title, widgetStyle }) => {
    
    const content = [];

    for (let x = 0; x < parseInt(rows ?? 1); x++) {
        content.push(
            <div key={'loading'+x}>
                <p className="loading-widget-item" style={{ width: '75%' }}>&nbsp;</p>
                <p className="loading-widget-item" style={{ width: '25%' }}>&nbsp;</p>
            </div>
        )
    }
    
    return (
        <div className={'content ' + widgetStyle}>
            {title && <Title title={title} />}
            {content}
        </div>
    )
}
